import React, { useEffect, useRef, useState } from "react";
import Footer from "../../common/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import RecentBlog from "../../assets/img/blog-item.jpg";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
const BlogDetail = () => {
  const owlRef = useRef(null);
  const [dynamicDataRender, setDynamicDataRender] = useState([]);
  const [blogData, setBlogData] = useState({});
  const query = new URLSearchParams(window.location.search);
  const blog_id = query.get("id");
  const [articleDescription, setArticleDescription] = useState("");
  useEffect(() => {
    document.title = "Tijoree";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.tijoree.money/blog/category/all`
        // `https://apidev.tijoree.money/blog/category/all`
      );
      setDynamicDataRender(Object.values(response.data));
      const allData = Object.values(response.data);
      for (const dataArray of allData) {
        const matchedBlog = dataArray.find(
          (item) => item.title.toString() === blog_id
        );
        if (matchedBlog) {
          setBlogData(matchedBlog);
          // const paragraphs = matchedBlog.articleDescription.split(/\r?\n|\r\n|\n\r|\n{2}|\n\n|\\n/);
          // const formattedText = paragraphs.map((paragraph, index) => (
          //     <p key={index}>{paragraph}</p>
          // ));
          // setArticleDescription(formattedText);
          // break; // Exit the loop once the matching blog data is found
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const item = blogData;
  return (
    <React.Fragment>
      <Helmet>
        <title> Blog Detail | Corporate Business Banking |Tijoree</title>
        <meta
          name="description"
          content="Discover Tijoree, your trusted partner for corporate business banking solutions. With a focus on delivering exceptional financial services, we empower businesses with cutting-edge digital banking tools and personalized support. Learn more about our comprehensive suite of services and how Tijoree can fuel your business growth. Contact us today and experience a new era of corporate banking excellence."
        />
      </Helmet>
      <div className="page-wrapper">
        {/* header section card start */}
        <div className="container-md">
          <div className="blog-top-detail">
            <div className="subtitle">{item?.category?.categoryName}</div>
            <div className="title">
              <Link to="#">{item.title}</Link>
            </div>
            <div className="date">
              {new Date(item.articleDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div className="blog-socials">
              {/* <ul>
                                <li><a href="#"></a></li>
                                <li><a href="#" className="twitter"></a></li>
                                <li><a href="#" className="linkedin"></a></li>
                                <li><a href="#" className="gmail"></a></li>
                            </ul> */}
            </div>
          </div>
          <div className="blog-banner">
            <img className="" src={item.imageUrl} alt="blog detail" />
          </div>
          <div
            className="blog-inner-content pb-4"
            dangerouslySetInnerHTML={{ __html: blogData.articleDescription }}
          />
        </div>
        {/* <div className="recent-blogs">
                    <div className="container-md">
                        <div className="section-title text-center">
                        <h3>Related Posts</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="list">
                                    <Link to={'/'}>
                                        <img
                                            className="w-100 h-100"
                                            src={RecentBlog}
                                            alt="image"
                                        />
                                        <h5>Lorem ipsum dolor sit amet consectetur. Quis mi felis cursus id fringilla pretium potenti at sit?</h5>
                                    </Link>
                                    <div className="date">12 June, 2023</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="list">
                                    <Link to={'/'}>
                                        <img
                                            className="w-100 h-100"
                                            src={RecentBlog}
                                            alt="image"
                                        />
                                        <h5>Lorem ipsum dolor sit amet consectetur. Quis mi felis cursus id fringilla pretium potenti at sit?</h5>
                                    </Link>
                                    <div className="date">12 June, 2023</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="list">
                                    <Link to={'/'}>
                                        <img
                                            className="w-100 h-100"
                                            src={RecentBlog}
                                            alt="image"
                                        />
                                        <h5>Lorem ipsum dolor sit amet consectetur. Quis mi felis cursus id fringilla pretium potenti at sit?</h5>
                                    </Link>
                                    <div className="date">12 June, 2023</div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div> */}
        <Footer />
      </div>
    </React.Fragment>
  );
};
export default BlogDetail;
